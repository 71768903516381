<template>
  <b-container class="page-container">
    <Breadcrumbs currentpage="Activatie van uw emailadres" />
    <b-alert v-if="invalidLink || !code" show variant="warning"
      ><font-awesome-icon icon="exclamation-circle" /> Activatie van je
      mailadres<br />

      De bevestigingslink uit je e-mail lijkt onjuist of is mogelijk verlopen.
      Je registratie als klant is niet afgerond.
    </b-alert>
    <b-row v-if="!invalidLink && code && doneLoading && !canLogin">
      <b-col xs="12" md="8">
        <h1 class="pb-3">Bevestiging van uw emailadres</h1>
        <p>
          Uw emailadres is succesvol bevestigd, als uw profiel al bevestigd is
          kunt u nu inloggen. Indien uw profiel nog niet bevestigd is kunt u pas
          inloggen nadat deze bevestigd is.
        </p>
      </b-col>
    </b-row>
    <b-row v-if="!invalidLink && code && doneLoading && canLogin">
      <b-col xs="12" md="8">
        <h1 class="pb-3">Bevestiging van je emailadres</h1>
        <h4 class="pb-3">Bedankt voor het verifieren van je emailadres!</h4>
        <p>
          Uw emailadres is succesvol bevestigd, als uw profiel al bevestigd is
          kunt u nu inloggen. Indien uw profiel nog niet bevestigd is kunt u pas
          inloggen nadat deze bevestigd is.
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
const { confirmUser } = require('@/services/AuthorizationService')
import Breadcrumbs from '@/components/header/TheBreadcrumbs'
export default {
  components: { Breadcrumbs },
  data() {
    return {
      invalidLink: false,
      doneLoading: false,
      canLogin: false
    }
  },
  computed: {
    code: function() {
      return this.$route.params.code || false
    }
  },
  mounted: async function() {
    const result = await confirmUser({ code: this.code })
    if (result.Message == 'InvalidModelState') {
      this.invalidLink = true
    }

    if (result.data == 'Exception_RegistrationConfirmWrongInput') {
      this.invalidLink = true
    }

    if (result.data === 'Succes_RegistrationAndUserConfirm') {
      this.canLogin = true
    }

    if (result.data === '') {
      this.invalidLink = true
    }

    this.doneLoading = true
  }
}
</script>

<style scoped lang="scss">
.page-container {
  h4 {
    font-size: 35px;
  }
  p {
    font-size: 18px;
  }
}
</style>
